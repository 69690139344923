const outcomes = [{
        title: "GA-2100",
        range: "Fashion",
        text: "G-SHOCK’s new icon, the best-selling GA-2100, is an evolution of the very first G-SHOCK watch design from 1983. In a short space of time it has impacted the world of street culture with its toughness, functionality and bold design, whilst inspiring a new standard of watches in the wider 2100 series.",
        url: "https://g-shock.co.uk/collections/2100?utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=fashion",
        video: "gshock-fashion-desktop.mp4",
        videoMobile: "gshock-fashion-mobile.mp4",
        recommendedProducts: [{
                title: "GA-B2100",
                image: "ga-b2100c-9aer.jpg",
                url: "https://g-shock.co.uk/?q=ga-b2100",
            },
            {
                title: "GA-2100",
                image: "ga-2100-1a_1_1.jpg",
                url: "https://g-shock.co.uk/?q=ga-2100",
            },
            {
                title: "GM-2100",
                image: "gm-2100-new.jpg",
                url: "https://g-shock.co.uk/?q=gm-2100",
            },
            {
                title: "DW-5600",
                image: "dw-5600bb-1er.jpg",
                url: "https://g-shock.co.uk/?q=dw-5600",
            },
        ],
    },
    {
        title: "GBD-H2000",
        range: "Sport",
        text: "G-SHOCK Sports Watches are built to withstand knocks and bumps even in the harshest environments, they also encompass daily health and fitness support functions. Featuring built in step tracking, GPS tracking and a whole variety of individual sports tracking functions helping you to monitor your fitness routines. in the CASIO watches app.",
        url: "https://g-shock.co.uk/collections/sports-watches?utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=sport",
        video: "gshock-sport-desktop.mp4",
        videoMobile: "gshock-sport-mobile.mp4",
        recommendedProducts: [{
                title: "GBD-H2000",
                image: "h2000-1a9er.jpg",
                url: "https://g-shock.co.uk/?q=GBD-H2000",
            },
            {
                title: "GBD-200",
                image: "gbd-200-2er.jpg",
                url: "https://g-shock.co.uk/?q=GBD-200",
            },
            {
                title: "GBA-900",
                image: "gba-red.jpg",
                url: "https://g-shock.co.uk/?q=GBA-900",
            },
            {
                title: "DW-H5600",
                image: "5600-2er.jpg",
                url: "https://g-shock.co.uk/?q=DW-H5600",
            },
        ],
    },
    {
        title: "GW-9500 MUDMAN",
        range: "Master of G",
        text: "Master of G watches are ruggedly stylish, and admired among G-SHOCK enthusiasts. MOG watches are worn by the military with G-SHOCK also collaborating with the Ministry Of Defence on a number of collections. The watches are loved thanks to their extreme shock resistance and ability to perform in various environments around the world.",
        url: "https://g-shock.co.uk/?q=mud&utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=mog",
        video: "gshock-master-of-g-desktop.mp4",
        videoMobile: "gshock-master-of-g-mobile.mp4",
        recommendedProducts: [{
                title: "GWG-2000",
                image: "gwg-2000-1a1er.jpg",
                url: "https://g-shock.co.uk/?q=GWG-2000",
            },
            {
                title: "GW-9500",
                image: "1er-mudman.jpg",
                url: "https://g-shock.co.uk/?q=GW-9500",
            },
            {
                title: "GW-9400",
                image: "gw-9400-ps-ps_1_.jpg",
                url: "https://g-shock.co.uk/?q=GW-9400",
            },
            {
                title: "GG-B100",
                image: "gg-b100-1a9.jpg",
                url: "https://g-shock.co.uk/?q=GG-B100",
            },
        ],
    },
    {
        title: "GST-B500",
        range: "Metal",
        text: "Offering smart yet adaptable premium timepieces, the metal collection boasts show stopping sleek designs that are bound to be noticed in any outfit or occasion whilst also offering fantastic adaptability thanks to the sheer level of durability, quality and shock resistance each one provides. The metal collection is all solar powered - powered by any light source (artificial or natural) - All of them also feature built in BLuetooth technology for automatic time changing as you travel as well as a phone finder.",
        url: "https://g-shock.co.uk/collections/metal?utm_campaign=g-shock-quiz&utm_medium=referal&utm_source=quiz&utm_term=metal",
        video: "gshock-metal-desktop.mp4",
        videoMobile: "gshock-metal-mobile.mp4",
        recommendedProducts: [{
                title: "GST",
                image: "gstd_1.jpg",
                url: "https://g-shock.co.uk/?q=GST",
            },
            {
                title: "GM-B2100",
                image: "black-gm-b.jpg",
                url: "https://g-shock.co.uk/?q=gm-b2100",
            },
            {
                title: "GMW",
                image: "gmw-b5000d-1er.jpg",
                url: "https://g-shock.co.uk/?q=gmw",
            },
            {
                title: "MTG",
                image: "prb-plp.jpg",
                url: "https://g-shock.co.uk/?q=mtg",
            },
        ],
    },
];

export default outcomes;