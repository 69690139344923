const images = [
    [{
            filename: "q-1-a.jpg",
            gridcolClass: "mt-6 lg:mt-4",
            testClipClass: "test-clip clip-1 relative z-0 lg:z-20",
            swipeClass: "absolute bg-black z-30 swipe-down",
        },
        {
            filename: "q-1-b.jpg",
            gridcolClass: "lg:-ml-24 lg:-mt-12",
            testClipClass: "test-clip clip-2 relative  z-10",
            swipeClass: "absolute bg-black z-30 swipe-up",
        },
        {
            filename: "q-1-c.jpg",
            gridcolClass: "mt-8 lg:-mt-28 lg:ml-28",
            testClipClass: "test-clip clip-3 relative z-10 lg:z-0",
            swipeClass: "absolute bg-black z-30 swipe-up switch-anim",
        },
        {
            filename: "q-1-d.jpg",
            gridcolClass: "mt-2 lg:-mt-40 lg:ml-8",
            testClipClass: "test-clip clip-4 relative",
            swipeClass: "absolute bg-black z-30 swipe-down switch-anim",
        },
    ],
    [{
            filename: "q-2-a.jpg",
            gridcolClass: "mt-6 lg:mt-4",
            testClipClass: "test-clip clip-1 relative z-0 lg:z-20",
            swipeClass: "absolute bg-black z-30 swipe-down",
        },
        {
            filename: "q-2-b.jpg",
            gridcolClass: "lg:-ml-24 lg:-mt-12",
            testClipClass: "test-clip clip-2 relative  z-10",
            swipeClass: "absolute bg-black z-30 swipe-up",
        },
        {
            filename: "q-2-c.jpg",
            gridcolClass: "mt-8 lg:-mt-28 lg:ml-28",
            testClipClass: "test-clip clip-3 relative z-10 lg:z-0",
            swipeClass: "absolute bg-black z-30 swipe-up switch-anim",
        },
        {
            filename: "q-2-d.jpg",
            gridcolClass: "mt-2 lg:-mt-40 lg:ml-8",
            testClipClass: "test-clip clip-4 relative",
            swipeClass: "absolute bg-black z-30 swipe-down switch-anim",
        },
    ],
    [{
            filename: "q-3-a.jpg",
            gridcolClass: "mt-6 lg:mt-4",
            testClipClass: "test-clip clip-1 relative z-0 lg:z-20",
            swipeClass: "absolute bg-black z-30 swipe-down",
        },
        {
            filename: "q-3-b.jpg",
            gridcolClass: "lg:-ml-24 lg:-mt-12",
            testClipClass: "test-clip clip-2 relative  z-10",
            swipeClass: "absolute bg-black z-30 swipe-up",
        },
        {
            filename: "q-3-c.jpg",
            gridcolClass: "mt-8 lg:-mt-28 lg:ml-28",
            testClipClass: "test-clip clip-3 relative z-10 lg:z-0",
            swipeClass: "absolute bg-black z-30 swipe-up switch-anim",
        },
        {
            filename: "q-3-d.jpg",
            gridcolClass: "mt-2 lg:-mt-40 lg:ml-8",
            testClipClass: "test-clip clip-4 relative",
            swipeClass: "absolute bg-black z-30 swipe-down switch-anim",
        },
    ],
    [{
            filename: "q-4-a.jpg",
            gridcolClass: "mt-6 lg:mt-4",
            testClipClass: "test-clip clip-1 relative z-0 lg:z-20",
            swipeClass: "absolute bg-black z-30 swipe-down",
        },
        {
            filename: "q-4-b.jpg",
            gridcolClass: "lg:-ml-24 lg:-mt-12",
            testClipClass: "test-clip clip-2 relative  z-10",
            swipeClass: "absolute bg-black z-30 swipe-up",
        },
        {
            filename: "q-4-c.jpg",
            gridcolClass: "mt-8 lg:-mt-28 lg:ml-28",
            testClipClass: "test-clip clip-3 relative z-10 lg:z-0",
            swipeClass: "absolute bg-black z-30 swipe-up switch-anim",
        },
        {
            filename: "q-4-d.jpg",
            gridcolClass: "mt-2 lg:-mt-40 lg:ml-8",
            testClipClass: "test-clip clip-4 relative",
            swipeClass: "absolute bg-black z-30 swipe-down switch-anim",
        },
    ],
    [{
            filename: "q-5-a.jpg",
            gridcolClass: "mt-6 lg:mt-4",
            testClipClass: "test-clip clip-1 relative z-0 lg:z-20",
            swipeClass: "absolute bg-black z-30 swipe-down",
        },
        {
            filename: "q-5-b.jpg",
            gridcolClass: "lg:-ml-24 lg:-mt-12",
            testClipClass: "test-clip clip-2 relative  z-10",
            swipeClass: "absolute bg-black z-30 swipe-up",
        },
        {
            filename: "q-5-c.jpg",
            gridcolClass: "mt-8 lg:-mt-28 lg:ml-28",
            testClipClass: "test-clip clip-3 relative z-10 lg:z-0",
            swipeClass: "absolute bg-black z-30 swipe-up switch-anim",
        },
        {
            filename: "q-5-d.jpg",
            gridcolClass: "mt-2 lg:-mt-40 lg:ml-8",
            testClipClass: "test-clip clip-4 relative",
            swipeClass: "absolute bg-black z-30 swipe-down switch-anim",
        },
    ],
    [],
    // Other questions' images
];

export default images