import React, { useState, useEffect } from "react";
import ImageGrid from "./ImageGrid";
import questions from "../assets/static/questions";
import outcomes from "../assets/static/outcomes";
import images from "../assets/static/images";
import { useNavigate } from "react-router";
import EmailForm from "./EmailForm";


function MultiStepQuiz({ setFinalResult, finalResult }) {
  const [imageGridKey, setImageGridKey] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [step, setStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showOptionErrorMessage, setShowOptionErrorMessage] = useState(false); // Add state variable

  const navigate = useNavigate(); //using navigate function from react-router-dom

  const handleOptionSelect = (questionIndex, optionIndex) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [questionIndex]: optionIndex,
    }));
  };

  const calculateResult = () => {
    const outcomePoints = outcomes.map(() => 0);

    for (const questionIndex in selectedOptions) {
      const optionIndex = selectedOptions[questionIndex];
      const outcomePointList =
        questions[questionIndex].options[optionIndex].outcomePoints;

      outcomePointList.forEach((outcomeIndex) => {
        outcomePoints[outcomeIndex] += 1;
      });
    }

    const maxPoints = Math.max(...outcomePoints);
    const bestOutcomeIndex = outcomePoints.indexOf(maxPoints);

    setFinalResult({
      outcome: outcomes[bestOutcomeIndex],
      points: outcomePoints,
    });

    window.dataLayer = window.dataLayer || []; window.dataLayer.push({
      'event': 'results',
      'range': outcomes[bestOutcomeIndex].range,
      'title': outcomes[bestOutcomeIndex].title,
    });
  };

  const [showEmailForm, setShowEmailForm] = useState(false);

  const handleNextStep = () => {

    let selectedOptionName = questions[step].options[selectedOptions[step]].title;

    window.dataLayer = window.dataLayer || []; window.dataLayer.push({
      'event': 'click',
      'question': step + 1,
      'answer': selectedOptionName
    });

    if (step + 1 < questions.length) {
      if (selectedOptions[step] !== undefined) {
        setAnimationClass("fade-animation");
        setShowOptionErrorMessage(false);
        setTimeout(() => {
          setImageGridKey((prevKey) => prevKey + 1);
          setStep(step + 1);
          setAnimationClass("fade-animation show");
        }, 500);
      } else {
        setShowOptionErrorMessage(true);
      }
    } else {
      calculateResult();
      setShowEmailForm(true)
      setStep(questions.length);
    }
  };

  const [showAnimation, setShowAnimation] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);

    setTimeout(() => {
      setShowAnimation(true);
    }, 0);

    setTimeout(() => {
      setIsVisible(true);
    }, 500);
  }, []);


  return (
    <section className={`overflow-hidden ${isVisible ? 'opacity-100' : 'opacity-0'} ${showAnimation && 'duration-300'}`}>
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-12 w-full">
        <div className="col-span-6 order-2 lg:order-1 text-left lg:min-h-screen flex items-center sm:mb-6 lg:mb-0 relative z-10 text-white px-5 md:px-8 xl:px-16">
          {step < questions.length ? (
            <div
              className={`caption flex flex-col w-full space-y-3 md:space-y-6 py-5 lg:py-24 lg:pb-20 ${animationClass}`}
            >
              <p className="text-lg font-bold md:text-xl xl:text-2xl uppercase border-b-2 border-white border-opacity-25 pb-1 text-casio-light-grey">
                <span>
                  Question <span className="text-casio-red">{step + 1}</span> of{" "}
                  {questions.length}
                </span>
              </p>
              <h2 className="font-heading uppercase leading-[1.125] text-2xl md:leading-[1.125] md:text-4xl xl:leading-[1.125] 2xl:text-5xl 2xl:leading-[1.125]">
                {questions[step].question}
              </h2>
              <div className="options-container">
                <ul className="grid gap-4 md:gap-6 grid-cols-2 choices">
                  {questions[step].options.map((option, index) => (
                    <li
                      key={option.option}
                      className={`option ${selectedOptions[step] === index ? "selected" : ""
                        }`}
                      onClick={() => handleOptionSelect(step, index)}
                    >
                      <span className="tick">
                        <svg
                          width="17"
                          height="12"
                          viewBox="0 0 17 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.26467 6.37683C1.125 6.23925 0.936625 6.16243 0.740575 6.16311C0.544524 6.16379 0.356685 6.24191 0.217974 6.38046C0.0792635 6.51901 0.000919778 6.70676 8.04712e-06 6.90281C-0.000903684 7.09885 0.0756909 7.28732 0.213107 7.42715L4.568 11.782C4.63702 11.8511 4.71898 11.906 4.80919 11.9434C4.89941 11.9808 4.99612 12 5.09378 12C5.19144 12 5.28815 11.9808 5.37836 11.9434C5.46858 11.906 5.55054 11.8511 5.61956 11.782L16.1278 1.27259C16.1977 1.20375 16.2532 1.12175 16.2913 1.03132C16.3293 0.940897 16.3491 0.843835 16.3494 0.745735C16.3498 0.647635 16.3307 0.550439 16.2933 0.459752C16.2558 0.369065 16.2008 0.286682 16.1314 0.217355C16.062 0.148029 15.9796 0.0931301 15.8889 0.0558263C15.7981 0.0185224 15.7009 -0.000448176 15.6028 8.03694e-06C15.5047 0.00046425 15.4077 0.0203385 15.3173 0.0584846C15.2269 0.0966307 15.145 0.152294 15.0762 0.222263L5.09316 10.2053L1.26467 6.37683Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <strong>{option.title}</strong>
                      <span className="description">{option.text}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <button
                className={`button button-primary self-start w-full ${Object.keys(selectedOptions).includes(step.toString())
                  ? ""
                  : "cursor-not-allowed"
                  }`}
                onClick={handleNextStep}
              >
                {step === questions.length - 1 ? "Get Results" : "Next Question"}
              </button>
              {showOptionErrorMessage && (
                <p className="text-red-400 text-sm text-center font-body p-4 bg-red-500 bg-opacity-10 border-red-500 border-2 border-opacity-30 mt-2 block">
                  Please select an option to proceed to the next question.
                </p>
              )}
            </div>
          ) : (
            null
          )}
        </div>
        <div className={`${animationClass} col-span-6 flex justify-start order-1 lg:order-2 overflow-hidden lg:fixed lg:w-1/2 lg:top-0 lg:bottom-0 lg:right-0 pt-14 md:pt-20 lg:pt-0`}>
          <ImageGrid key={imageGridKey} step={step} images={images} />
        </div>
      </div>
      {
        showEmailForm && <EmailForm finalResult={finalResult} />
      }
    </section>
  );
}

export default MultiStepQuiz;
