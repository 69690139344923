import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'


function EmailForm({finalResult}) {

  const navigate = useNavigate()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const [checkboxSelected, setCheckboxSelected] = useState(false)

  function getHUTK() {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'hubspotutk') {
        return value;
      }
    }
    return null;
  }

  const submitHandler = async () => {
    if (firstName === '' || lastName === '' || email === '') return alert('Please fill in all fields')
    if (email.indexOf('@') === -1) return alert('Please enter a valid email address')

    let data = {
      fields: [
        {
          "name": "email",
          "value": email
        },
        {
          "name": "firstname",
          "value": firstName
        },
        {
          "name": "lastname",
          "value": lastName
        },
        {
          "name": "quiz_result",
          "value": finalResult?.outcome?.range || 'Test'
        }
      ],
      context: {
        hutk: ":hutk",
        pageUri: window.location.href,
        pageName: "Result Page"
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to receive communications from G-SHOCK.",
          communications: [
            {
              value: checkboxSelected,
              subscriptionTypeId: 11091111,
              text: "I agree to receive communications from G-SHOCK."
            }
          ]
        }
      }
    }

    const hutkValue = getHUTK();
    if (hutkValue) {
      data.context.hutk = hutkValue;
    }


    const res = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/2210734/67b98bd5-f689-40ae-b681-68ea3ea26a4a`, data, {})

    if (res.status === 200) navigate('/result')
    else alert('Something went wrong, please try again')
    return res;

  }

  useEffect(() => {
   console.log(checkboxSelected)
  }, [checkboxSelected])
  

  return (
    <div className='fixed left-0 top-0 bg-black bg-opacity-0 w-screen h-screen z-[100] flex justify-center items-center'>
      <div className={`w-11/12 max-w-[600px] m-auto bg-white p-4 pb-2 md:p-6 md:pb-3`} >
        <h2 className='text-black mb-4 md:mb-6 font-heading uppercase leading-[1.25] text-xl md:leading-[1.125] md:text-4xl'>Please enter your details to view your results</h2>
        <div className='bg-white grid grid-cols-1 gap-4'>
          <div className='grid grid-cols-2 gap-4 items-center justify-between'>
            <input className='text-gray-700 border-[1px] py-2 px-4' value={firstName} onChange={e => setFirstName(e.target.value)} type="text" placeholder='First Name' />
            <input className='text-gray-700 border-[1px] py-2 px-4' value={lastName} onChange={e => setLastName(e.target.value)} type="text" placeholder='Last Name' />
          </div>
          <input className='text-gray-700 border-[1px] py-2 px-4' value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder='Email*' required />

          <div className='flex items-center gap-4'>
            <input type="checkbox" id='checkbox' value={checkboxSelected} onClick={e => setCheckboxSelected(!checkboxSelected)} />
            <label className='text-[14px] text-gray-600 relative top-0.5' htmlFor="checkbox">I agree to receive communications from G-SHOCK.</label>
          </div>

          <p className='text-[14px] text-gray-600'>You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our <Link className='font-bold underline' target='_blank' to={`https://g-shock.co.uk/privacy-policy`}>Privacy Policy</Link>.</p>
          <button className='py-2 px-4 bg-black text-white font-heading duration-300 opacity-80 hover:opacity-100' onClick={() => submitHandler()}>Submit</button>
        </div>
      </div>
    </div>

  )
}

export default EmailForm