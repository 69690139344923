import React from 'react';

const ImageGrid = ({ step, images }) => {
  return (
      <div className="grid grid-cols-4 lg:grid gap-0 lg:grid-cols-2 lg:py-12 pb-0 image-grid lg:-mt-4 lg:ml-[2vw] px-10 md:px-16 lg:px-0 w-full lg:w-auto relative md:-right-5 lg:right-0">
        {images[step].map((image, index) => (
          <ImageItem
            key={index}
            imageSrc={image.filename}
            gridcolClass={image.gridcolClass}
            testClipClass={image.testClipClass}
            swipeClass={image.swipeClass}
          />
        ))}
      </div>
  );
};

const ImageItem = ({ imageSrc, gridcolClass, testClipClass, swipeClass }) => {
  return (
    <div className={`gridcol ${gridcolClass}`}>
      <div className={testClipClass}>
        <div className={`absolute ${swipeClass}`}></div>
        <img src={imageSrc} className="w-full h-full object-cover object-right" alt="" />
      </div>
    </div>
  );
};

export default ImageGrid;