import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import '../index.css';
import { useLocation } from 'react-router-dom';


const Header = () => {

    const location = useLocation();

    useEffect(() => {

        let pageSlug = location.pathname

        window.dataLayer = window.dataLayer || []; window.dataLayer.push({
            'event': 'Pageview',
            'pagePath': pageSlug,
            'pageTitle': pageSlug === '/' ? 'Home' : pageSlug === '/question' ? 'Question' : pageSlug === '/result' ? 'Result' : 'No Data'
        });

    }, [location]);

    return (
        <header id="header" className="opacity-0 transition fade-me2 absolute inset-x-0 z-50 pb-0 p-5 md:p-8 xl:p-8 xl:px-16 flex justify-center lg:justify-start">
            <Link to="/">
                <svg className="h-auto w-28 md:w-32 lg:w-28 xl:w-40" width="190" height="32" viewBox="0 0 190 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 3.9919L3.89928 0H21.358L25.4287 3.9919V10.503H17.681V7.92822H7.65962V24.1737H17.769V20.0151H13.4761V12.0915H25.6V27.934L21.5294 31.9166H4.15861L0 27.9294V3.9919ZM28.675 19.9687V12.2211H40.3589V19.9687H28.675ZM43.3783 15.8518V4.07062L47.3609 0H65.1346L69.3303 3.9919V10.5123H61.3696V7.9699H51.2648V12.2999H65.3522L69.3858 16.5048V28.1007L65.2226 31.9074H47.4535L43.3412 28.0174V21.7563H51.1722V24.2014H61.1983V20.0428H47.6342L43.3829 15.8425L43.3783 15.8518ZM91.3088 0V12.2258H81.3245V0H73.4472V32H81.3245V20.0938H91.3088V32H99.1954V0H91.3088ZM132.927 27.9294V3.9919L137.091 0H154.684L158.666 3.9919V12.0868H150.923V8.01621H140.944V24.2107H150.97V19.9317H158.629V27.934L154.6 31.9166H136.836L132.932 27.934L132.927 27.9294ZM188.953 0H179.33L170.795 11.7997V0H162.737V31.912H170.795V20.4504L179.2 31.912H188.74L177.579 15.9768L188.953 0ZM107.406 0.152822H124.652L128.709 4.16324L128.389 27.9572L124.425 31.9213H107.133L103.354 28.1424V4.20955L107.401 0.152822H107.406ZM120.961 7.80782V24.1737H111.092V7.76151L120.961 7.80782Z" fill="white" />
                    </g>
                </svg>
            </Link>
        </header>
    );
};

export default Header;