const questions = [{
        question: "Time to head out! - What's the outfit of choice?",
        options: [{
                title: "Smart",
                text: "Suited up ready for the boardroom & beyond",
                outcomePoints: [3],
            },
            {
                title: "Casual",
                text: "Your go to trainers and matching tee",
                outcomePoints: [0, 1],
            },
            {
                title: "Action",
                text: "Geared up for your latest outdoor adventure",
                outcomePoints: [2, 1],
            },
            {
                title: "Athletic",
                text: "Kitted, with your stopwatch at the ready!",
                outcomePoints: [1],
            },
        ],
    },
    {
        question: "It’s the weekend! What are you up to?",
        options: [{
                title: "Exploring",
                text: "Discovering the nature on your door step and beyond",
                outcomePoints: [2, 1],
            },
            {
                title: "Training",
                text: "In the gym, on the track or in the pool - chasing that new PB",
                outcomePoints: [1],
            },
            {
                title: "Shopping",
                text: "Hunting for the latest look",
                outcomePoints: [0, 3],
            },
            {
                title: "Socialising",
                text: "Good food, Good friends, Good vibes",
                outcomePoints: [0, 3],
            },
        ],
    },
    {
        question: "You’re at the Cinemas waiting in line to watch the latest blockbuster – but which one will you choose?",
        options: [{
                title: "Action",
                text: "Impossible missions & Secret tech",
                outcomePoints: [2, 3],
            },
            {
                title: "Adventure",
                text: "Mysterious locations and epic discoveries",
                outcomePoints: [2, 3],
            },
            {
                title: "Sport",
                text: "Boxing greats & motivational stories",
                outcomePoints: [1],
            },
            {
                title: "Comedy",
                text: "Classic or animation, laugh out loud moments",
                outcomePoints: [0],
            },
        ],
    },
    {
        question: "What colour pallete best describes you?",
        options: [
            { title: "Bold", text: "Loud & Vibrant", outcomePoints: [0, 1] },
            {
                title: "Military",
                text: "Olive greens and stealthy blacks",
                outcomePoints: [2],
            },
            {
                title: "Subtle",
                text: "Sleek metal finishes & subdued tones",
                outcomePoints: [3],
            },
            {
                title: "On Trend",
                text: "The latest trending colours",
                outcomePoints: [0],
            },
        ],
    },
    {
        question: "What tech do you want on your wrist?",
        options: [{
                title: "Data",
                text: "GPS, step tracking, calorie burn – I need a full report STAT.",
                outcomePoints: [1],
            },
            {
                title: "Tools",
                text: "Compass, Altimeter & Barometer – Essential when exploring",
                outcomePoints: [2],
            },
            {
                title: "The Works",
                text: "World Time updates – Built in phone finder and Solar Power is must!",
                outcomePoints: [3, 1, 0],
            },
            {
                title: "Style over tech",
                text: "Just the looks for me",
                outcomePoints: [0, 3],
            },
        ],
    },
];

export default questions;