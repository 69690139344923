
import React from "react";
import MultiStepQuiz from "../components/MultiStepQuiz";

const Question = ({ setFinalResult, finalResult }) => {
    return (
        <div>
            <MultiStepQuiz setFinalResult={setFinalResult} finalResult={finalResult} />
        </div>
    );
};

export default Question;