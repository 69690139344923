
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './Pages/Home';
import Question from './Pages/Question';
import Result from './Pages/Result';
import Header from './components/Header';


function App() {
    const [finalResult, setFinalResult] = useState([]);

    useEffect(() => {
        console.log(finalResult)
    }, [finalResult])

    return (
        <Router>
            <Header />

            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/question' element={<Question setFinalResult={setFinalResult} finalResult={finalResult} />} />
                <Route path='/result' element={<Result finalResult={finalResult} />} />
            </Routes>
        </Router>
    );
}

export default App;